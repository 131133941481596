<template>
  <Breadcrumb :urls="urls"  :translationloaded="translationloaded" :translate="translate"></Breadcrumb>
  <WhatsAppButton></WhatsAppButton>
  <div class="account-info-sec" v-if="translationloaded==true">
    <div class="container">
      <div class="row">
        <div class="col-lg-3">
          <div id="show-hidden-menu" class="dashBoarsBtnMain" @click="toggledashboard()">
            <i class="fa fa-th-large" aria-hidden="true"></i>{{translate('dashboard')}}
          </div>
          <div id ="vdasboard"  class="hidden-menu" style="display: none;">
           <SideBar
              :translationloaded="translationloaded"
              :translate="translate"
            ></SideBar>
          </div>
          <div class="mainSideDiplay">
            <SideBar
              :translationloaded="translationloaded"
              :translate="translate"
            ></SideBar>
          </div>
        </div>
        <div class="col-lg-9">
          <div class="account-details-sec changePassworddiv">
            <h3>{{translate('ChangePassword')}}</h3>
          <div class="mb-4 inputbox">
            <input
              type="password"
              :class="{ error_border: errors.old_password_error }"
              v-model="update_password_form.old_password"
              name="old_password"
              :placeholder="translate('oldPassword')"
            />

             <p class="text-danger">{{errors.old_password_msg}}</p>
           </div>
           
           <div class="mb-4 inputbox">
              <input
              type="password"
              name="new_password"
              :class="{ error_border: errors.new_password_error }"
              v-model="update_password_form.new_password"
              :placeholder="translate('newPassword')"
            />
             <p class="text-danger">{{errors.new_password_msg}}</p>
           </div>
           
           <div class="mb-4 inputbox">
            <input
              type="password"
              name="confirm_password"
              :class="{ error_border: errors.confirm_password_error }"
              v-model="update_password_form.confirm_password"
              :placeholder="translate('confirmPassword')"
            />

             <p class="text-danger">{{errors.new_password_msg}}</p>
           </div>

            <div class="savebtn">
              <button type="submit" @click="Save">{{translate('ChangePassword')}} </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Breadcrumb from "../components/Breadcrumb";
import SideBar from "../components/my_account/SideBar";
import WhatsAppButton from "@/components/WhatsAppButton";
import helper from "@/helper/helper";
import { mapActions } from "vuex";
export default {
  name: "ChangePassword",
  components: {
    Breadcrumb,
    SideBar,
    WhatsAppButton
  },
   props:[
     'translationloaded',
     'translate'
  ],
  data() {
    return {
      urls: [
        {
          path: "/",
          name: "Home",
        },

        {
          path: "/change-password",
          name: "ChangeYourPassword",
        },
      ],
      update_password_form: {
        old_password: "",
        new_password: "",
        confirm_password: "",
      },
      errors: {
        old_password_error: "",
        old_password_msg: "",
        new_password_error: "",
        new_password_msg: "",
        confirm_password_error: "",
        confirm_password_msg: "",
      },
      form_submit_status: "",
    };
  },
  mounted(){
    helper.backtoTop();
  },
  methods: {
    toggledashboard()
    {
       var x = document.getElementById("vdasboard");
      if (x.style.display === "none") {
        x.style.display = "block";
      } else {
        x.style.display = "none";
      }
    },
    ...mapActions(["updatePassword"]),
    Save() {
      let formValidateErrorStatus = this.validateForm();
      if (formValidateErrorStatus == false) {
        let $this = this;

        let payload = {
          custId: localStorage.getItem("custId"),
          oldPassword: $this.update_password_form.old_password,
          newPassword: $this.update_password_form.new_password,
        };
        $this
          .updatePassword(payload)
          .then(function(response) {
            $this.$toast.success(`${response.data.Message}`);
            $this.update_password_form.old_password='',
            $this.update_password_form.new_password='';
             $this.update_password_form.confirm_password='';
          })
          .catch(function(error) {
            $this.$toast.error(`${error.response.data.Message}`);
          });
      }
    },
    validateForm() {
      let errorStatus = false;
      if (this.update_password_form.old_password == "") {
        this.errors.old_password_error = true;
        errorStatus = true;
      } else {
        if (this.update_password_form.old_password.length < 6) {
          this.errors.old_password_error = true;
          errorStatus = true;
          this.errors.old_password_msg = "Please enter at least 6 characters.";
        } else {
          this.errors.old_password_msg = "";
          this.errors.old_password_error = false;
        }
      }

      if (this.update_password_form.new_password == "") {
        this.errors.new_password_error = true;
        errorStatus = true;
      } else {
        if (this.update_password_form.new_password.length < 6) {
          this.errors.new_password_error = true;
          errorStatus = true;
          this.errors.new_password_msg = "Please enter at least 6 characters.";
        } else {
          this.errors.new_password_msg = "";
          this.errors.new_password_error = false;
        }
      }

      if (this.update_password_form.password == "") {
        this.errors.confirm_password_error = true;
        errorStatus = true;
      } else {
        if (
          this.update_password_form.new_password !=
          this.update_password_form.confirm_password
        ) {
          this.errors.confirm_password_error = true;
          errorStatus = true;
          this.errors.confirm_password_msg = "Enter the same password";
           this.$toast.error(`Password doesn't match`);
        } else {
          this.errors.confirm_password_error = false;
          this.errors.confirm_password_msg = "";
        }
      }

      return errorStatus;
    },
  },
};
</script>
<style scoped>
input {
  width: 100%;
  box-shadow: inherit;
  height: 45px;
  padding-left: 15px;
  margin-bottom: 15px;
  border: 1px solid #b3b3b396;
  font-size: 15px;
  border-radius: 0;
}
.error_border {
  border: 1px solid red !important;
}
.inputbox{
  position: relative;
}
.inputbox p{
  position: absolute;
  top:44px
}
</style>
