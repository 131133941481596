<template >

<div class="account-lft-bar" v-if="translationloaded==true">
        <ul>
        <li :class="{'order':true,'active':isActivatedLink('MyOrder')}" >
            <router-link :to="`/my-order`">{{translate('viewMyOrders')}}</router-link>
           
        </li>
        <li  :class="{'wishlist':true,'active':isActivatedLink('MyWishlist')}" >
            <router-link :to="`/my-wishlist`">{{translate('myWishlist')}}</router-link>
        </li>
        <!-- <li  :class="{'cart':true,'active':isActivatedLink('MyCart')}"  >
            <router-link :to="`/my-cart`">My Cart</router-link>
           </li> -->
        <li :class="{'profile':true,'active':isActivatedLink('MyProfile')}" >
             <router-link :to="`/my-profile`">{{translate('MyProfile')}}</router-link>
        </li>
        <li :class="{'profile':true,'active':isActivatedLink('MyWallet')}" >
             <router-link :to="`/my-wallet`">{{translate('MyWallet')}}</router-link>
        </li>
        <li :class="{'address':true,'active':isActivatedLink('MyAddrss')}"  >
             <router-link :to="`/my-address`">{{translate('myAddress')}}</router-link>
        </li>
        <li :class="{'coupons':true,'active':isActivatedLink('MyCoupons')}" >
             <router-link :to="`/my-coupons`">{{translate('coupons')}}</router-link>
           
        </li> 
         <li :class="{'coupons':true,'active':isActivatedLink('ChangePassword')}" >
             <router-link :to="`/change-password`">{{translate('changePassword')}}</router-link>
           
        </li>  
         <li :class="{'coupons':true,'active':isActivatedLink('affilationProgram')}" >
             <router-link :to="`/affiliation-program`">{{translate('affilationProgram')}}</router-link>
           
        </li> 
         <li :class="{'coupons':true,'active':isActivatedLink('affiliationEarning')}" >
             <router-link :to="`/affiliation-earning`">{{translate('affiliationEarnings')}}</router-link>
           
        </li>  
        <li :class="{'coupons':true,'active':isActivatedLink('ContactUs')}" >
             <router-link :to="`/contact-us`">{{translate('contactUs')}}</router-link>
           
        </li>   
        <li @click="logout">{{translate('logout')}}</li>    
        </ul>   
    </div>
    
</template>
<script>
export default {
    name:'Sidebar',
    props:[
        'translationloaded',
        'translate',
    ],
    data(){
        return {
            'active_link':'order'
        }
    },
    methods: {
        logout(){
            localStorage.removeItem('custId');
            localStorage.removeItem('customer_token');
            // location.reload();
             window.location.href="/";
        },
        makeActiveLink(link){
             this.active_link = link;
        },
        isActivatedLink(link){
            
            if(this.active_link == link){
                return true;
            }else{
                return false;
            }
        },
       
        
    },
     mounted(){
          this.active_link = this.$route.name;
            
        }
    
}
</script>
<style scoped>
    
</style>