<template >
        <div class="ps-breadcrumb">
        <div class="ps-container">
            <ul class="breadcrumb" v-if="translationloaded==true">
                <li v-for="(url,index) in urls" :key="index">
                    <template v-if="(urls.length-1)!=index" >

                        <router-link :to="url.path">{{ translate(url.name)}}</router-link>
                    </template>
                    <template v-else>
                        {{translate(url.name)}}
                    </template>
                </li>   
               
            </ul>
        </div>
    </div>
</template>
<script>
export default {
    name:'Breadcrumb',
    props:[
        'urls',
         'translationloaded',
        'translate'
    ],
    data(){
        return{
            lang:'',
        }
           
     
    },
    

}
</script>
<style scopped>
    
</style>